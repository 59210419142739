import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  handleError(error: HttpErrorResponse) {
    //TODO 메뉴숨겨야함
    if (error.status == 401 && localStorage.length != 0) {
      localStorage.clear();
      return window.location.href = '/login';
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error 원인:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status} | ` +
        `error: ${JSON.stringify(error.error)} | ` +
        `Headers: ${JSON.stringify(error.headers)} | ` +
        `Error Message: ${error.message} | ` +
        `statusText: ${error.statusText} | ` +
        `type: ${error.type} | name: ${error.name}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
